import React from "react";
import Image from "next/image";
import useInView from "react-cool-inview";
import block from 'bem-css-modules';
import featureModule from "../../styles/components/Feature.module.scss";


export default function Feature({spaceTop, spaceBottom, post}) {

    block.setSettings({
      modifierDelimiter: '--'
    });

    const val1 = post?.Counts?.Number_1 || '';
    const val2 = post?.Counts?.Number_2 || '';
    const val3 = post?.Counts?.Number_3 || '';
    const val4 = post?.Counts?.Number_4 || '';
    const val5 = post?.Counts?.Number_5 || '';

    const feature = block(featureModule);

    const { observe, inView } = useInView({
      onEnter: ({ unobserve }) => {
        unobserve();
        const timer = setTimeout(() => {        
            const obj1 = document.getElementById("val1");
            const obj2 = document.getElementById("val2");
            const obj3 = document.getElementById("val3");
            const obj4 = document.getElementById("val4");
            const obj5 = document.getElementById("val5");            
            animateValue(obj1, 0, val1, 1000);
            animateValue(obj2, 0, val2, 1000);
            animateValue(obj3, 0, val3, 1000);
            animateValue(obj4, 0, val4, 1000);
            animateValue(obj5, 0, val5, 1000);

            },500)
            return () => {
              clearTimeout(timer)
            }
      }
    });

    function numberWithCommas(number) {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }

    function animateValue(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);

        // obj.innerHTML = (progress * (end - start) + start).toString().substring(0, 6);
        const valString = (progress * (end - start) + start).toString().substring(0, 7);
        const valNum = Number(valString);
        obj.innerHTML = numberWithCommas(valNum);


        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    } 
         
    return (
      <div className={feature('')} data-numbers={post?.SeccondSideTitle || ""} ref={observe}>
        <div className={`${feature('holder')}` + ' ' + `${spaceTop && feature("holder--spaceTop")}` + ' ' + `${spaceBottom && feature("holder--spaceBottom")}`} >                
          <section className={feature('details')}>
              <div className={feature('data')}>
              <span className={feature('headerTextLabel')}>{post?.SeccondSideTitle || ""} </span>
                  <div data-icon="icon-ellipse" className={feature('list', {'icon-avatar': true})}>
                    <h3 className={feature('head')}> 
                    <span className={feature('numbers', {more: true})} id={ inView && 'val1'}>0</span> 
                    <span className={feature('category')}>{post?.Counts?.Title_1 || ""}</span> </h3>                    
                  </div>

                  <div data-icon="icon-ellipse" className={feature('list', {'icon-globe': true})}>
                    <h3 className={feature('head')}>
                      <span className={feature('numbers')} id={ inView && 'val2'}>0</span>  
                      <span className={feature('category')}>{post?.Counts?.Title_2 || ""}</span></h3>
                  </div>

                  <div data-icon="icon-ellipse" className={feature('list', {'icon-tile': true})}>
                    <h3 className={feature('head')}>
                      <span className={feature('numbers', {more: true})} id={ inView && 'val3'}>0</span>  
                      <span className={feature('category')}>{post?.Counts?.Title_3 || ""}</span></h3>
                  </div>

                  <div data-icon="icon-ellipse" className={feature('list', {'icon-star': true})}>
                    <h3 className={feature('head')}>
                      <span className={feature('numbers')} id={ inView && 'val4'}>0</span>  
                      <span className={feature('category')}>{post?.Counts?.Title_4 || ""}</span></h3>
                  </div>

                  <div data-icon="icon-ellipse" className={feature('list', {'icon-pen': true})}>
                    <h3 className={feature('head')}>
                      <span className={feature('numbers', {more: true})} id={ inView && 'val5'}>0</span>  
                      <span className={feature('category')}>{post?.Counts?.Title_5 || ""}</span></h3>
                  </div>
              </div>
              
              <figure className={`${feature('image')}` + ' ' + ` ${ inView && feature('image--animate')}`}>
              <Image                     
                    src={post?.Image?.url || ''}                    
                    alt={"blog"}
                    width={486} 
                    height={586}
                    layout="responsive"                    
                />
              </figure>
              
          </section>
        </div>
      </div>
    );
  }